import React from "react";
import styled from "styled-components";
import twemoji from "twemoji";

const Wrapper = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	font-style: normal;
	vertical-align: middle;

	img {
		display: block;
		width: auto;
		height: 4rem;
	}
`;
export default function Emoji(props) {
	return props.children ? (
		<Wrapper
			dangerouslySetInnerHTML={{
				__html: String(
					twemoji.parse(props.children, {
						folder: "svg",
						ext: ".svg",
					})
				).replace(
					"twemoji.maxcdn.com/v",
					"cdnjs.cloudflare.com/ajax/libs/twemoji"
				),
			}}
			className={props.className}
			onClick={props.onClick || (() => "")}
		/>
	) : (
		""
	);
}
