import React, { useContext } from "react";
import styled from "styled-components";

import { currentMonth } from "utils/months";
import ModalContext from "utils/ModalContext";

import Emoji from "components/base/Emoji";
import Tile from "components/misc/Tile";
import Year from "./result/Year";
import InformationSvg from "../../components/base/svg/information.svg";
import useWindowSize from "../../hooks/useWindowSize";

let Title = styled.h2`
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	font-size: 2.5em;
	color: ${(props) => props.theme.trizzyColor};
	line-height: 1;
	${(props) => props.theme.mq.small} {
		font-size: 1.5em;
	}
`;
const Flex = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
`;
const Local = styled.div`
	font-size: 1.2em;
	font-weight: 700;
	color: ${(props) =>
		props.local ? props.theme.trizzy.green : props.theme.trizzyColor};
	cursor: pointer;
	${(props) => props.theme.mq.small} {
		font-size: 0.7em;
	}
`;
const Score = styled.div`
	font-style: normal;
	cursor: pointer;
	color: ${(props) => props.theme.trizzyColor};
	${(props) => props.theme.mq.small} {
		font-size: 0.9em;
	}
`;
const ScoreNumber = styled.span`
	font-size: 1.2rem;
	font-weight: 900;
	${(props) => props.theme.mq.small} {
		font-size: 1em;
	}
`;
const Text = styled.p`
	position: relative;
	margin: 1em 0 0;
	text-align: center;
`;
export default function Result(props) {
	const { setCO2E, setLocal } = useContext(ModalContext);
	const { width } = useWindowSize();
	const mobile = width <= 650 ? "12px" : "20px";
	return (
		<Tile>
			<Title>
				<span>{props.product.label.fr}</span>
				<Emoji>{props.product.emoji}</Emoji>
			</Title>
			<Year
				months={props.product.months}
				local={props.product.local ? 1 : 0}
				iframe={props.iframe}
			/>
			{props.product.months.includes(currentMonth) && (
				<Flex>
					<Local
						onClick={() => setLocal(true)}
						local={props.product.local ? 1 : 0}
					>
						{props.product.local
							? `Ce produit est local`
							: `Ce produit n'est pas local`}{" "}
						<InformationSvg
							width={mobile}
							changeColor={props.product.local ? "green" : "red"}
						></InformationSvg>
					</Local>
					<Score onClick={() => setCO2E(true)}>
						<ScoreNumber high={props.product.CO2 > 1 ? 1 : 0}>
							{props.product.CO2}
						</ScoreNumber>{" "}
						kgCO<sub>2</sub>e/kg{" "}
						<InformationSvg width={mobile}></InformationSvg>
					</Score>
				</Flex>
			)}
			{props.product.text && (
				<Text>
					{
						props.product.text[
							props.product.months.includes(currentMonth) ? "in" : "out"
						]
					}
				</Text>
			)}
		</Tile>
	);
}
